<template>
  <v-col cols="12" :xs="xs" :md="md">
    <v-col class="sis-width-100" v-if="type !== 'password'">

      <v-text-field v-if="!tooltip || tooltip === ''" v-model="inputVal" :label="label" :append-icon="appendIcon" :rules="rules" :type="!type ? 'text' : type" :counter="length" autocomplete="off">
        <template slot="prepend">
          <slot></slot>
        </template>
        <template slot="append">
          <slot name="append"></slot>
        </template>
      </v-text-field>

      <v-tooltip v-if="tooltip && tooltip !== ''" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-bind="attrs" v-on="on" v-model="inputVal" :label="label" :append-icon="appendIcon" :rules="rules" :type="!type ? 'text' : type" :counter="length" autocomplete="off">
            <template slot="prepend">
              <slot></slot>
            </template>
            <template slot="append">
              <slot name="append"></slot>
            </template>
          </v-text-field>
        </template>
        <span>{{pattern}}</span>
      </v-tooltip>
    </v-col>

    <v-col class="sis-float-left" cols="12" :xs="xs" :md="md" v-if="type === 'password'">
      <v-text-field v-model="inputVal" :label="label" :rules="rules" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'" @click:append="show = !show" autocomplete="off">
        <template slot="prepend">
          <slot></slot>
        </template>
      </v-text-field>
    </v-col>
  </v-col>
</template>

<script>

export default {
  name: 'nsis-Inputfield',
  props: {
    label: String,
    xs: String,
    md: String,
    type: String,
    length: Number,
    tooltip: String,
    appendIcon: String,
    required: Boolean,
    noSpaces: Boolean,
    pattern: String,
    click: Function,

    patternPassword: String,
    password: String,
  },
  data: function() {
    return {
      show: false
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    rules() {
      const rules = [];
      if (this.required) {
        const rule = v => !!v || this.$t('validation.required')
        rules.push(rule)
      }
      if (this.length) {
        const rule = v => (v || '').length <= this.length || this.$t('validation.length', {length : this.length})
        rules.push(rule)
      }
      if (this.noSpaces) {
        const rule = v => (v || '').indexOf(' ') < 0 || this.$t('validation.spaces')
        rules.push(rule)
      }
      if (this.pattern) {
        const rule = v => (!v || v === '') || new RegExp(this.pattern).test(v) || this.$t('validation.pattern')
        rules.push(rule)
      }
      if (this.password) {
        const rule = v => (this.password === v) || this.$t('validation.match')
        rules.push(rule);
      }
      if (this.patternPassword) {
        const rule = v => (!v || v === '') || new RegExp(this.patternPassword).test(v) || this.$t('validation.patternPassword')
        rules.push(rule)
      }
      return rules
    }
  }
}
</script>

<style scoped>
.sis-width-100 {
  width: 100%;
}
</style>