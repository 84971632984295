<template>
  <div class="sis-navbar-content">
    <div class="sis-navbar-line"></div>

    <v-toolbar prominent>
      <img class="sis-navbar-logo" alt="Vue logo" src="../assets/logo_90.png">
      
      <v-spacer></v-spacer>

      <div class="sis-cursor-pointer sis-hide-on-print" style="margin-right: 100px; height: 30px;"><i class="fas fa-user sis-font-size-25"></i> {{ username }}</div>
      <div class="sis-cursor-pointer sis-hide-on-print" @click="navigateLogin()" style="height: 30px; line-height: 33px;">{{ $t('logout') }}</div>
      
      <div class="sis-navbar-pull-bottomright sis-hide-on-print">
        <div @click="navigateStartseite()" class="sis-navbar-tab" v-bind:class="active == 'HOME' ? 'sis-active' : ''">
          <div class="sis-navbar-tabtitle"><div class="sis-pull-bottom">{{ $t('home.title') }}</div></div>
          <div class="sis-navbar-indicator"></div>
        </div>
        <div @click="navigatePruefung()" class="sis-navbar-tab" v-bind:class="active == 'ADMISSION' ? 'sis-active' : ''">
          <div class="sis-navbar-tabtitle"><div class="sis-pull-bottom">{{ $t('admissionNew.title') }}</div></div>
          <div class="sis-navbar-indicator"></div>  
        </div>
        <div @click="navigatePostbox()" class="sis-navbar-tab" v-bind:class="active == 'POSTBOX' ? 'sis-active' : ''">
          <div class="sis-navbar-tabtitle"><div class="sis-pull-bottom">{{ $t('postbox.title') }}</div></div>
          <div class="sis-navbar-indicator"></div>  
        </div>
        <div @click="navigateContact()" class="sis-navbar-tab" v-bind:class="active == 'CONTACT' ? 'sis-active' : ''">
          <div class="sis-navbar-tabtitle"><div class="sis-pull-bottom">{{ $t('contact.title') }}</div></div>
          <div class="sis-navbar-indicator"></div>  
        </div>
      </div>
    </v-toolbar>

    <div class="sis-navbar-headline-content">
      <span class="sis-navbar-headline">{{title}}</span>
    </div>
  </div>
</template>

<script>
import router from '../mixins/router.js'

export default {
  name: 'nsis-Navbar',
  props: {
    title: String,
    active: String
  },
  mixins: [router],
  data: function () {
    return {
      username: ""
    };
  },
  created: function () {
    this.$store.dispatch("getUsernameJWT").then((username) => {
      this.username = username;
    });
  },
}
</script>

<style>
  .sis-navbar-content {
    height: 198px;
    width: 100%;
  }

  .sis-navbar-line {
    height: 10px;
    width: 100%;

    background-color: var(--primary-color);
  }

  .sis-navbar-logo {
    padding: 15px 15px 15px 85px;
  }

  .sis-navbar-headline-content {
    height: 60px;
    width: 100%;

    padding: 5px 5px 5px 100px;

    color: var(--white-text-color);
    background-color: var(--primary-color);

    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }

  .sis-navbar-headline {
    font-style: normal;
    font-size: 32px;
  }

  .sis-navbar-pull-bottomright {
    position: absolute;
    bottom: 0;
    right: 100px;

    height: 90px;
  }

  .sis-navbar-tab {
    float:left;
    height: 100%;
    width: 175px;
    text-align: center;
    cursor: pointer;
  }

  .sis-navbar-tabtitle {
    position: relative;
    height: calc(100% - 10px);
  }

  .sis-pull-bottom {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
  }
  
  .sis-cursor-pointer {
    cursor: pointer;
  }

  .sis-navbar-indicator {
    width: 20px;
    height: 20px;
    margin: auto;
  }

  .sis-active > .sis-navbar-indicator, .sis-navbar-tab:hover > .sis-navbar-indicator {
    transform: rotate(45deg);
    background-color: white;
  }
</style>