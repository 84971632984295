import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../plugins/store'

import Login from '../views/Login.vue'
import Startseite from '../views/Startseite.vue'
import Wasserfahrzeug from '../views/Wasserfahrzeug.vue'
import WasserfahrzeugAbschluss from '../views/WasserfahrzeugAbschluss.vue'
import Luftfahrzeug from '../views/Luftfahrzeug.vue'
import LuftfahrzeugAbschluss from '../views/LuftfahrzeugAbschluss.vue'
import Postbox from '../views/Postbox.vue'
import Contact from '../views/Contact.vue'
import NewPassword from '../views/NewPassword.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/newPassword',
    name: 'NewPassword',
    component: NewPassword,
  },
  {
    path: '/dashboard',
    name: 'Startseite',
    component: Startseite,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/postbox',
    name: 'Postbox',
    component: Postbox,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/boat',
    name: 'Wasserfahrzeug',
    component: Wasserfahrzeug,
    meta: {
      requiresAuth: true,
      requiresRole: "ROLE_BOOT"
    }
  },
  {
    path: '/boat/completion',
    name: 'WasserfahrzeugAbschluss',
    component: WasserfahrzeugAbschluss,
    meta: {
      requiresAuth: true,
      requiresRole: "ROLE_BOOT"
    }
  },
  {
    path: '/aircraft',
    name: 'Luftfahrzeug',
    component: Luftfahrzeug,
    meta: {
      requiresAuth: true,
      requiresRole: "ROLE_FLUGZEUG"
    }
  },
  {
    path: '/aircraft/completion',
    name: 'LuftfahrzeugAbschluss',
    component: LuftfahrzeugAbschluss,
    meta: {
      requiresAuth: true,
      requiresRole: "ROLE_FLUGZEUG"
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    // lazy-loaded when the route is visited.
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch("isLoggedIn").then((isLoggedIn) => {
    //Wenn angemeldet auf Dashboard umleiten
    if(to.matched.some(record => record.name === 'Login') && isLoggedIn){
      next({name: "Startseite"})
    }

    //Wenn nicht angemeldet auf Login umleiten
    if(to.matched.some(record => record.meta.requiresAuth)){
      if(!isLoggedIn) {
        next({name: "Login"})
      }

      //Wenn angemeldet aber nicht die richtige Rolle
      store.dispatch("getUserRole").then((roles) => {
        if(to.matched.some(record => record.meta.requiresRole && !roles.includes(record.meta.requiresRole))){
          next({name: "Startseite"})
        }
      });
    }

    //Wenn nicht umgeleitet weiter auf die ausgewählte Seite
    next();
  })
})

export default router
