<template>
  <div class="sis-footer-content sis-hide-on-print">
    <span style="float:left">Version: {{ appVersion }}</span>
    <span>© 2024 Bundeskriminalamt | <a class="sis-cursor-pointer" target="_blank" href='https://www.bka.de/DE/Service/Impressum/impressum_node.html'>Impressum</a> | <a class="sis-cursor-pointer" target="_blank" href='https://www.bka.de/DE/Service/Datenschutzerklaerung/datenschutz_node.html'>Datenschutzhinweise</a></span>
  </div>
</template>

<script>
import router from '../mixins/router.js'
import {version} from '../../package'

export default {
  name: 'nsis-Footer',
  mixins: [router],
  data: () => ({
    appVersion: version
  }),
}
</script>

<style>
  .sis-footer-content {
    height: 52px;
    width: 100%;

    text-align: center;
    padding: 15px;

    color: var(--white-text-color);;
    background-color: var(--primary-color);
  }
</style>