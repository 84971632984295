<template>
  <div class="sis-main">
    <Navbar :title="title" active="ADMISSION"/>
    <div class="sis-content">
      <v-form>
        <Card xs="10" sm="10" md="8" lg="8" xl="8">
          <v-list-item-title>
            <div class="sis-float-left">
              <h3>{{ detail }}</h3>
              <h4 v-if="subDetailIntern !== null">{{ subDetailIntern }}</h4>
              <h4 v-if="subDetail !== null">{{ subDetail }}</h4>
            </div>
            <div class="sis-float-right sis-hide-on-print">
              <!--<v-btn class="sis-button-space" color="primary" @click="download()">
                {{ $t('export') }}
              </v-btn>-->
              <a id="downloadAnchorElem" class="sis-hide"></a>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="sis-button-space" color="primary" @click="print" v-bind="attrs" v-on="on">
                    {{ $t('print') }}
                  </v-btn>
                </template>
                <span>{{ $t('printAsPDFTooltip') }}</span>
              </v-tooltip>
            </div>
            <h4 class="sis-error sis-hide-on-print">
              {{ $t('completionInfo') }}
            </h4>
          </v-list-item-title>
          <v-card-text>
            <slot></slot>
          </v-card-text>
          <v-card-actions>
          </v-card-actions>
        </Card>
        
        <v-row class="sis-row sis-hide-on-print">
          <v-col class="sis-push-center" cols="12" xs="10" md="8">
            <v-btn class="sis-button-space" color="primary" @click="navigatePruefung()">
              {{ $t('admissionNew.again') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <NsisFooter/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import NsisFooter from '@/components/Footer.vue'
import Card from '@/components/Card.vue'

import router from '../mixins/router.js'

export default {
  name: 'nsis-Luftfahrzeug',
  components: {
    Navbar,
    NsisFooter,
    Card
  },
  mixins: [router],
  props: {
    title: String,
    detail: String,
    subDetail: String,
    subDetailIntern: String,
  },
  mounted() {
    this.print();
  },
  methods: {
    print() {
      window.print();
    }
  }
}
</script>

<style scoped>
  .sis-error {
    clear: both;
    color: var(--error-text-color);
  }
</style>
