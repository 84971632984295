var moment = require("moment")
moment.locale('de');

var format = "YYYY-MM-DD[T]HH:mm:ss"

export default {
    methods: {
        formatStringToDate(string) {
            if (string) {
                var date = moment(string, format)
                return date.format("LLLL");
              } else {
                return "";
              }
        }
    }
 }