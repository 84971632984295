<template>
  <Abschluss :title="$t('water.completionTitle')" :detail="detail" :subDetailIntern="subDetailIntern" :subDetail="subDetail">
    <div>
      <h3>{{ $t('water.detail') }}</h3>
      <p>
        <b>{{ $t('water.name') }}:</b> {{ boat.name }}<br>
        <b>{{ $t('water.registrationNumber') }}:</b> {{ boat.registrationNumber }}<br>
        <b>{{ $t('water.certificationNumber') }}:</b> {{ boat.certificationNumber }}<br>
        <b>{{ $t('water.sailNumber') }}:</b> {{ getText(boat, "sailNumbers") }}<br>
        <b>{{ $t('water.manufacturerNumber') }}:</b> {{ boat.manufacturerNumber }}<br>
        <b>{{ $t('water.externalIdentification') }}:</b> {{ boat.externalIdentification }}<br>
        <b>{{ $t('water.hullIdentificationNumber') }}:</b> {{ boat.hullIdentificationNumber }}<br>
        <b>{{ $t('water.otherNumber1') }}:</b> {{ boat.otherNumber1 }}<br>
        <b>{{ $t('water.otherNumber2') }}:</b> {{ boat.otherNumber2 }}<br>
        <b>{{ $t('water.transponderRFIDID') }}:</b> {{ this.getText(boat, "transponderRFIDTagSet.setId") }}<br>
        <b>{{ $t('water.transponderRFIDNumber') }}:</b> {{ this.getText(boat, "transponderRFIDTagSet.tagNumber") }}<br>
      </p>
    </div>
    <div v-for="(boatEngine, index) in boat.boatEngines" :key="index">
      <h3>{{ $t('water.detailMotor') }} {{ index + 1 }}</h3>
      <p>
        <b>{{ $t('water.serialNumber') }}:</b> {{ boatEngine.serialNumber }}<br>
        <b>{{ $t('water.otherNumber1') }}:</b> {{ boatEngine.otherNumber1 }}<br>
        <b>{{ $t('water.otherNumber2') }}:</b> {{ boatEngine.otherNumber2 }}<br>
        <b>{{ $t('water.transponderRFIDID') }}:</b> {{ getText(boatEngine, "transponderRFIDTagSet.setId") }}<br>
        <b>{{ $t('water.transponderRFIDNumber') }}:</b> {{ getText(boatEngine, "transponderRFIDTagSet.tagNumber") }}<br>
      </p>
    </div>
  </Abschluss>
</template>

<script>
import Abschluss from '@/template/Abschluss.vue'

export default {
  name: 'WasserfahrzeugAbschluss',
  components: {
    Abschluss
  },
  data: function () {
    return {
      extRequestId: "",
      messageId: "",
      findByNameRequestId: "",
      findByFuzzySearchRequestId: "",
      boat: {
        boatEngines: [{
          transponderRFIDTagSet: {}
        }],
        transponderRFIDTagSet: {}
      }
    };
  },
  created: function () {
    let boatData = this.$store.getters.getBoatData;
    this.boat = boatData.boat;
    this.messageId = boatData.messageId;
    this.findByNameRequestId = boatData.findByNameRequestId;
    this.findByFuzzySearchRequestId = boatData.findByFuzzySearchRequestId;
    this.extRequestId = boatData.extRequestId;
  },
  methods: {
    getText(object, path) {
      let split = path.split(".")
      for (let i in path.split(".")) {
        if (object === undefined) {
          return "";
        }
        object = object[split[i]]
      }

      if (Array.isArray(object)) {
        return object.join(', ');
      } else {
        return object;
      }
    }
  },
  computed: {
    detail: function () {
      return this.$t('completionDetail', {id: this.messageId})
    },
    subDetailIntern: function () {
      let id = "";
      if (this.findByNameRequestId !== null) {
        id += this.findByNameRequestId;
      }
      if (this.findByFuzzySearchRequestId !== null) {
        if (id !== "") {
          id += ", ";
        }
        id += this.findByFuzzySearchRequestId;
      }
      return this.$t('completionSubDetailIntern', {id: id})
    },
    subDetail: function () {
      if (this.extRequestId === "") {
        return null;
      }
      return this.$t('completionSubDetail', {id: this.extRequestId})
    }
  }
}
</script>
