<template>
  <Abschluss :title="$t('air.completionTitle')" :detail="detail" :subDetailIntern="subDetailIntern" :subDetail="subDetail">
    <div>
      <h3>{{ $t('air.detail') }}</h3>
      <p>
        <b>{{ $t('air.name') }}:</b> {{ aircraft.name }}<br>
        <b>{{ $t('air.icaoRegistration') }}:</b> {{ aircraft.icaoRegistration }}<br>
        <b>{{ $t('air.serialNumber') }}:</b> {{ aircraft.serialNumber }}<br>
        <b>{{ $t('air.otherNumber1') }}:</b> {{ aircraft.otherNumber1 }}<br>
        <b>{{ $t('air.otherNumber2') }}:</b> {{ aircraft.otherNumber2 }}<br>
        <b>{{ $t('air.transponderRFIDID') }}:</b> {{ getText(aircraft, "transponderRFIDTagSet.setId") }}<br>
        <b>{{ $t('air.transponderRFIDNumber') }}:</b> {{ getText(aircraft, "transponderRFIDTagSet.tagNumber") }}<br>
      </p>
    </div>
    <div v-for="(aircraftEngine, index) in aircraft.aircraftEngines" :key="index">
      <h3>{{ $t('air.detailMotor') }} {{ index + 1 }}</h3>
      <p>
        <b>{{ $t('air.serialNumber') }}:</b> {{ aircraftEngine.serialNumber }}<br>
        <b>{{ $t('air.serialNumber') }}:</b> {{ aircraftEngine.serialNumber }}<br>
        <b>{{ $t('air.otherNumber1') }}:</b> {{ aircraftEngine.otherNumber1 }}<br>
        <b>{{ $t('air.otherNumber2') }}:</b> {{ aircraftEngine.otherNumber2 }}<br>
      </p>
    </div>
  </Abschluss>
</template>

<script>
import Abschluss from '@/template/Abschluss.vue'

export default {
  name: 'LuftfahrzeugAbschluss',
  components: {
    Abschluss
  },
  data: function () {
    return {
      extRequestId: "",
      messageId: "",
      findByNameRequestId: "",
      findByFuzzySearchRequestId: "",
      aircraft: {
        transponderRFIDTagSet: {},
        aircraftEngines: [{}]
      }
    };
  },
  methods: {
    getText(object, path) {
      let split = path.split(".")
      for (let i in path.split(".")) {
        if (object === undefined) {
          return "";
        }
        object = object[split[i]]
      }

      if (Array.isArray(object)) {
        return object.join(', ');
      } else {
        return object;
      }
    }
  },
  created: function () {
    let aircraftData = this.$store.getters.getAircraftData;
    this.aircraft = aircraftData.aircraft;
    this.messageId = aircraftData.messageId;
    this.findByNameRequestId = aircraftData.findByNameRequestId;
    this.findByFuzzySearchRequestId = aircraftData.findByFuzzySearchRequestId;
    this.extRequestId = aircraftData.extRequestId;
  },
  computed: {
    detail: function () {
      return this.$t('completionDetail', {id: this.messageId})
    },
    subDetailIntern: function () {
      let id = "";
      if (this.findByNameRequestId !== null) {
        id += this.findByNameRequestId;
      }
      if (this.findByFuzzySearchRequestId !== null) {
        if (id !== "") {
          id += ", ";
        }
        id += this.findByFuzzySearchRequestId;
      }
      return this.$t('completionSubDetailIntern', {id: id})
    },
    subDetail: function () {
      if (this.extRequestId === "") {
        return null;
      }
      return this.$t('completionSubDetail', {id: this.extRequestId})
    }
  }
}
</script>
