<template>
  <v-col cols="12" :xs="xs" :md="md">
    <v-col class="sis-width-100">
      <v-combobox multiple v-model="inputVal" :label="label" chips deletable-chips class="tag-input" :rules="rules"/>
    </v-col>
  </v-col>
</template>

<script>

export default {
  name: 'ChiptagInputfield',
  props: {
    label: String,
    xs: String,
    md: String,
    length: Number,
    required: Boolean,
    noSpaces: Boolean,
    pattern: String
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    rules() {
      const rules = [];
      if (this.required) {
        const rule = v => !!v || this.$t('validation.required')
        rules.push(rule)
      }
      if (this.length) {
        const rule = v => (v || '').length <= this.length || this.$t('validation.length', {length : this.length})
        rules.push(rule)
      }
      if (this.noSpaces) {
        const rule = v => (v || '').indexOf(' ') < 0 || this.$t('validation.spaces')
        rules.push(rule)
      }
      if (this.pattern) {
        const rule = v => (!v || v === '') || new RegExp(this.pattern).test(v) || this.$t('validation.pattern')
        rules.push(rule)
      }
      return rules
    }
  }
}
</script>

<style>
  .sis-width-100 {
    width: 100%;
  }
  .v-chip.v-size--default {
    height: 22px !important;
  }
  .v-select.v-select--chips .v-select__selections {
    min-height: 32px !important;
  }
</style>