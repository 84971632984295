import Vue from 'vue'
import Vuex from 'vuex'

var jwt = require('jsonwebtoken');

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    boatData: undefined,
    aircraftData: undefined
  },
  actions: {
    /* LOGIN */
    setLoginToken(state, token) {
      sessionStorage.setItem("token", token)
    },
    setPublicKey(state, key) {
      sessionStorage.setItem("key", key)
    },
    getLoginToken() {
      return sessionStorage.getItem("token")
    },
    getPublicKey() {
      return sessionStorage.getItem("key")
    },
    removeLoginToken() {
      sessionStorage.removeItem("token");
    },
    removePublicKey() {
      sessionStorage.removeItem("key");
    },

    isLoggedIn() {
      var token = sessionStorage.getItem("token")
      var key = sessionStorage.getItem("key")
      if (token && token !== "" && key && key != "") {
        try {
          jwt.verify(token, key);
          return true;
        } catch(error) {
          console.log(error);
          return false;
        }
      } else {
        return false;
      }
    },
    getUserRole() {
      let token = sessionStorage.getItem("token")
      let key = sessionStorage.getItem("key")
      if (token && token !== "" && key && key != "") {
        try {
          var payload = jwt.verify(token, key);
          return payload.Authorities;
        } catch(error) {
          console.log(error);
          return null;
        }
      } else {
        return null;
      }
    },
    getUsernameJWT() {
      let token = sessionStorage.getItem("token")
      let key = sessionStorage.getItem("key")
      if (token && token !== "" && key && key != "") {
        try {
          var payload = jwt.verify(token, key);
          return payload.sub;
        } catch(error) {
          console.log(error);
          return null;
        }
      } else {
        return null;
      }
    },
  },
  mutations: {
    setBoatData(state, boatData) {
      state.boatData = boatData
    },
    setAircraftData(state, aircraftData) {
      state.aircraftData = aircraftData
    }
  },
  getters: {
    getBoatData(state) {
      return state.boatData
    },
    getAircraftData(state) {
      return state.aircraftData
    }
  }
})