<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
  @import './assets/variable.css';

  html {
    overflow: auto !important; 
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;

    color: var(--dark-text-color);
    background-color: var(--primary-background);
  }

  .sis-main {
    height: 100%;
    width: 100%;
  }

  .sis-content {
    height: calc(100% - 250px);
  }

  .sis-row {
    width: 100%;
    margin: 0px !important;
  }

  .sis-plus-button {
    width: 60px !important;
    min-width: 60px !important;
    height: 60px !important;
    font-size: 35px !important;
    padding: 0 !important;
    border-radius: 50% !important;
    text-align: center;
  }

  .sis-font-size-25 {
    font-size: 25px;
  }

  .sis-font-size-20 {
    font-size: 20px;
  }

  .sis-push-center {
    margin: 0 auto;
  }

  .sis-text-center {
    text-align: center;
  }

  .sis-float-left {
    float: left;
  }

  .sis-float-right {
    float: right;
  }

  .sis-button-space {
    margin: 0 5px 0 5px;
  }

  @media print {
    .sis-hide-on-print {
      display: none !important;
    }
  }
</style>