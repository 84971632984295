<template>
  <div class="sis-main">
    <Navbar :title="$t('water.subTitle')" active="ADMISSION"/>
    <v-alert dense type="error" v-if="error">
      {{ $t('emptyRequest') }}
    </v-alert>
    <div class="sis-content">
      <v-form ref="form" v-model="valid" lazy-validation>
        <Card xs="10" md="8">
          <v-list-item-title>
            <h3>{{ $t('extRequestId.title') }}</h3>
          </v-list-item-title>
          <v-card-text>
            <v-row no-gutters>
              <Inputfield sm="12" md="12" :length="length.extRequestId" v-model="extRequestId" :label="$t('extRequestId.number')">
                <template v-slot:append>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <i class="fas fa-info-circle sis-font-size-20" v-bind="attrs" v-on="on"></i>
                    </template>
                    <span>{{ $t('extRequestId.tooltip') }}</span>
                  </v-tooltip>
                </template>
              </Inputfield>
            </v-row>
          </v-card-text>
          <v-card-actions>
          </v-card-actions>
        </Card>

        <div class="sis-text-center sis-push-center col-md-8 col-12">
          <hr>
          <h3>{{ $t('vehicle') }}</h3>
        </div>

        <Card xs="10" md="8">
          <v-list-item-title>
            <h3>{{ $t('water.detail') }}</h3>
          </v-list-item-title>
          <v-card-text>
            <v-row no-gutters>
              <Inputfield sm="12" md="6" v-model="boat.name"
                :label="$t('water.name')" :length="length.name" :pattern="pattern.default"/>
              <Inputfield sm="12" md="6" v-model="boat.registrationNumber"
                :label="$t('water.registrationNumber')" :length="length.registrationNumber" :pattern="pattern.default"/>
              <Inputfield sm="12" md="6" v-model="boat.certificationNumber"
                :label="$t('water.certificationNumber')" :length="length.certificationNumber" :pattern="pattern.default"/>
              <ChiptagInputfield sm="12" md="6" v-model="boat.sailNumbers"
                                 :label="$t('water.sailNumber')" :length="length.sailNumbers" :pattern="pattern.default"/>
              <Inputfield sm="12" md="6" v-model="boat.manufacturerNumber"
                :label="$t('water.manufacturerNumber')" :length="length.manufacturerNumber" :pattern="pattern.default"/>
              <Inputfield sm="12" md="6" v-model="boat.externalIdentification"
                :label="$t('water.externalIdentification')" :length="length.externalIdentification" :pattern="pattern.default"/>
              <Inputfield sm="12" md="6" v-model="boat.hullIdentificationNumber"
                :label="$t('water.hullIdentificationNumber')" :length="length.hullIdentificationNumber" :pattern="pattern.default"/>
              <Inputfield sm="12" md="6" v-model="boat.otherNumber1"
                :label="$t('water.otherNumber1')" :length="length.otherNumber1" :pattern="pattern.default"/>
              <Inputfield sm="12" md="6" v-model="boat.otherNumber2"
                :label="$t('water.otherNumber2')" :length="length.otherNumber2" :pattern="pattern.default"/>
              <Inputfield sm="12" md="6" v-model="boat.transponderRFIDTagSet.setId"
                :label="$t('water.transponderRFIDID')" :length="length.transponderRFIDID" :pattern="pattern.default"/>
              <ChiptagInputfield sm="12" md="6" v-model="boat.transponderRFIDTagSet.tagNumber"
                                 :label="$t('water.transponderRFIDNumber')" :length="length.transponderRFIDNumber" :pattern="pattern.default"/>
            </v-row>
          </v-card-text>
          <v-card-actions>
          </v-card-actions>
        </Card>

        <div class="sis-text-center sis-push-center col-md-8 col-12">
          <hr>
          <h3>{{ $t('motoren') }}</h3>
        </div>

        <Card xs="10" md="8" v-for="(boatEngine, index) in boat.boatEngines" :key="boatEngine.key">
          <v-list-item-title>
            <h3 class="sis-float-left">{{ $t('water.detailMotor') }} {{ index + 1 }}</h3>
            <div class="sis-float-right" @click="removeMotor(index)"><i class="fas fa-trash sis-font-size-20"></i></div>
          </v-list-item-title>
          <v-card-text>
            <v-row no-gutters>
              <Inputfield sm="12" md="6" v-model="boatEngine.serialNumber"
                :label="$t('water.serialNumber')" :length="length.serialNumber" :pattern="pattern.default"/>
              <Inputfield sm="12" md="6" v-model="boatEngine.otherNumber1"
                :label="$t('water.otherNumber1')" :length="length.otherNumber1" :pattern="pattern.default"/>
              <Inputfield sm="12" md="6" v-model="boatEngine.otherNumber2"
                :label="$t('water.otherNumber2')" :length="length.otherNumber2" :pattern="pattern.default"/>
              <Inputfield sm="12" md="6" v-model="boatEngine.transponderRFIDTagSet.setId"
                :label="$t('water.transponderRFIDID')" :length="length.transponderRFIDID" :pattern="pattern.default"/>
              <ChiptagInputfield sm="12" md="6" v-model="boatEngine.transponderRFIDTagSet.tagNumber"
                :label="$t('water.transponderRFIDNumber')" :length="length.transponderRFIDNumber" :pattern="pattern.default"/>
            </v-row>
          </v-card-text>
          <v-card-actions>
          </v-card-actions>
        </Card>

        <v-row class="sis-row">
          <v-col class="sis-push-center sis-text-center" cols="12" xs="10" md="8">
            <v-btn class="sis-plus-button" @click="createNewMotor()">
              <i class="fas fa-plus"></i>
            </v-btn>
          </v-col>
        </v-row>
        
        <v-row class="sis-row">
          <v-col class="sis-push-center" cols="12" xs="10" md="8">
            <v-btn class="sis-button-space" color="primary" :disabled="!valid" @click="sendBoatWait()">
              {{ $t('send') }}
            </v-btn>
            <v-btn class="sis-button-space" @click="navigateStartseite()">
              {{ $t('exit') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <NsisFooter/>
  </div>
</template>

<script>
import ChiptagInputfield from '@/components/ChiptagInputfield.vue'
import Inputfield from '@/components/Inputfield.vue'
import Navbar from '@/components/Navbar.vue'
import NsisFooter from '@/components/Footer.vue'
import Card from '@/components/Card.vue'

import router from '../mixins/router.js'
import rest from '../mixins/rest.js'

export default {
  name: 'nsis-Wasserfahrzeug',
  components: {
    ChiptagInputfield,
    Inputfield,
    Navbar,
    NsisFooter,
    Card
  },
  mixins: [router, rest],
  data: function() {
    return {
      valid: false,
      error: false,
      extRequestId: "",
      boat: {
        boatEngines: [{
          key: 1,
          transponderRFIDTagSet: {}
        }],
        transponderRFIDTagSet: {}
      },
      pattern: {
        default: '^(([\u0009-\u000a\u000d\u0020-\u007e\u00a1-\u00ac\u00ae-\u0107\u010a-\u011b\u011e-\u0123\u0126-\u0131\u0134-\u015b\u015e-\u016b\u016e-\u017e\u018f\u01a0-\u01a1\u01af-\u01b0\u01b7\u01cd-\u01d4\u01de-\u01df\u01e4-\u01f0\u01f4-\u01f5\u01fa-\u01ff\u0218-\u021b\u021e-\u021f\u022a-\u022b\u022e-\u0233\u0259\u0292\u1e02-\u1e03\u1e0a-\u1e0b\u1e10-\u1e11\u1e1e-\u1e21\u1e24-\u1e27\u1e30-\u1e31\u1e40-\u1e41\u1e44-\u1e45\u1e56-\u1e57\u1e60-\u1e63\u1e6a-\u1e6b\u1e80-\u1e85\u1e8c-\u1e93\u1e9e\u1ea0-\u1ea7\u1eaa-\u1eac\u1eae-\u1ec1\u1ec4-\u1ed3\u1ed6-\u1edd\u1ee4-\u1ef9\u20ac])|(\u004d\u0302|\u004e\u0302|\u006d\u0302|\u006e\u0302|\u0044\u0302|\u0064\u0302|\u004a\u030c|\u004c\u0302|\u006c\u0302))*$'
      },
      length: {
        extRequestId: 50,

        name: 200,
        registrationNumber: 50,
        certificationNumber: 50,
        sailNumbers: 50,
        manufacturerNumber: 200,
        externalIdentification: 50,
        hullIdentificationNumber: 50,

        otherNumber1: 50,
        otherNumber2: 50,

        transponderRFIDID: 100,
        transponderRFIDNumber: 255,

        serialNumber: 50
      }
    };
  },
  methods: {
    createNewMotor() {
      let key = 1
      if (this.boat.boatEngines.length > 0) {
        key = this.boat.boatEngines[this.boat.boatEngines.length - 1].key + 1
      }
      this.boat.boatEngines.push({key: key, transponderRFIDTagSet: {}})
    },
    removeMotor(index) {
      this.$delete(this.boat.boatEngines, index)
    },
    sendBoatWait() {
      setTimeout(() => this.sendBoat(), 1000);
    },
    sendBoat() {
      if(this.$refs.form.validate()) {
        let postData = {
          boat: this.boat,
          extRequestId: this.extRequestId,
        }

        let these = this;
        this.callApiBearerAuth(() => {
          let api = these.getUploadApi();
          let opts = {
            "boatRequest": postData
          };
          api.uploadBoat(opts, (error, data, response) => {
            if (error) {
              if (error.status === 400) {
                this.error = true;
              }
            } else {
              postData.messageId = response.body.messageId;
              postData.findByNameRequestId = response.body.findByNameRequestId;
              postData.findByFuzzySearchRequestId = response.body.findByFuzzySearchRequestId;
              these.$store.commit("setBoatData", postData)
              these.$router.push({ name: "WasserfahrzeugAbschluss" })
            }
          });
        });
      }
    }
  }
}
</script>
