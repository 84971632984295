<template>
  <div class="sis-main">
    <Navbar :title="$t('home.title')" active="HOME"/>
    <div class="sis-content">
      <Card xs="10" md="8">
        <v-list-item-title>
          <h3>{{ $t('home.title') }}</h3>
        </v-list-item-title>
        <v-card-text>
           {{ $t('home.description') }}
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </Card>
    </div>
    <NsisFooter/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import NsisFooter from '@/components/Footer.vue'
import Card from '@/components/Card.vue'

export default {
  name: 'nsis-Startseite',
  components: {
    Navbar,
    NsisFooter,
    Card
  }
}
</script>
