var NsisPortal = require('nsis_portal');

export default {
    methods: {
      callApiBasicAuth(username, password, callback) {
        const defaultClient = NsisPortal.ApiClient.instance;

        if (process.env.VUE_APP_API_MODE === "PROD") {
          defaultClient.basePath = window.location.protocol + "//" + window.location.host
        }

        defaultClient.defaultHeaders = {};

        const basicAuth = defaultClient.authentications['basicAuth'];
        basicAuth.username = username;
        basicAuth.password = password;
        callback();
      },
      callApiBearerAuth(callback) {
        const defaultClient = NsisPortal.ApiClient.instance;

        if (process.env.VUE_APP_API_MODE === "PROD") {
          defaultClient.basePath = window.location.protocol + "//" + window.location.host
        }

        defaultClient.defaultHeaders = {};

        const bearerAuth = defaultClient.authentications['bearerAuth'];
        this.$store.dispatch("getLoginToken").then((token) => {
          bearerAuth.accessToken = token;
          callback();
        });
      },
      callApi(callback) {
        const defaultClient = NsisPortal.ApiClient.instance;

        if (process.env.VUE_APP_API_MODE === "PROD") {
          defaultClient.basePath = window.location.protocol + "//" + window.location.host
        }

        defaultClient.defaultHeaders = {};
        callback();
      },
      getPostboxApi() {
        return new NsisPortal.PostboxApi();
      },
      getUploadApi() {
        return new NsisPortal.UploadApi();
      },
      getLoginApi() {
        return new NsisPortal.LoginApi();
      },
    }
 }