<template>
  <div class="sis-main">
    <Navbar :title="$t('postbox.title')" active="POSTBOX"/>
    <div class="sis-content">
      <Card xs="10" md="8">
        <v-list-item-title>
          <h3>{{ $t('postbox.title') }}</h3>
        </v-list-item-title>
        <v-card-text>
           <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t('postbox.messageId') }}
                  </th>
                  <th class="text-left">
                    {{ $t('postbox.interalIds') }}
                  </th>
                  <th class="text-left">
                    {{ $t('postbox.extRequestId') }}
                  </th>
                  <th class="text-left">
                    {{ $t('postbox.requestAt') }}
                  </th>
                  <th class="text-left">
                    {{ $t('postbox.responseAt') }}
                  </th>
                  <th class="text-left">
                    {{ $t('postbox.status') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="request in requests" :key="request.messageId" v-bind:class="getColorClass(request)">
                  <td>{{ request.messageId }}</td>
                  <td>{{ request.internalRequestId1 }}
                    {{ request.internalRequestId2 }}</td>
                  <td>{{ request.extRequestId }}</td>
                  <td>{{ formatStringToDate(request.requestAt) }}</td>
                  <td>{{ formatStringToDate(request.responseAt) }}</td>
                  <td>{{ request.status }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </Card>
    </div>
    <NsisFooter/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import NsisFooter from '@/components/Footer.vue'
import Card from '@/components/Card.vue'

import moment from '../mixins/moment.js'
import rest from '../mixins/rest.js'

import '../assets/variable.css';

export default {
  name: 'nsis-Postbox',
  components: {
    Navbar,
    NsisFooter,
    Card
  },
  mixins: [moment, rest],
  data: function() {
    return {
      requests: []
    };
  },
  created: function() {
    let these = this;
    this.callApiBearerAuth(() => {
      let api = this.getPostboxApi();
      api.getPostbox((error, data) => {
        if (error) {
          console.error(error);
        } else {
          these.requests = data;
        }
      });
    });
  },
  methods: {
    getColorClass(request) {
      return {
        'sis-requested': request.status === "Eingereicht",
        'sis-search-error': request.status === "Error",
        'sis-noHit': request.status === "Kein Treffer",
        'sis-hit': request.status === "Treffer"
      }
    },
  }
}
</script>

<style>
  .sis-requested {
    background-color: var(--warning);
  }
  .sis-noHit {
    background-color: var(--success);
  }
  .sis-hit {
    background-color: var(--error);
  }
  .sis-search-error {
    background-color: #ababab;
  }
</style>