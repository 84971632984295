<template>
  <div class="sis-main">
    <v-form ref="form" @keyup.enter.native="sendNewPassword()" v-model="valid" lazy-validation>
      <v-alert dense type="error" v-if="error">
        {{ $t('newPassword.error') }}
      </v-alert>
      <Card xs="6" md="3" class="sis-push-center">
        <v-list-item-title class="sis-login-header">
          <h2>{{ $t('newPassword.title') }}</h2>
        </v-list-item-title>
        <v-card-text>
          <v-container>
            <v-row>
              <Inputfield sm="12" md="12" v-model="newPassword.password" :patternPassword="pattern.default" :label="$t('newPassword.password')" type="password" :required=true>
                <i class="fas fa-lock sis-font-size-25"></i>
              </Inputfield>

              <Inputfield sm="12" md="12" v-model="newPassword.repeatPassword" :patternPassword="pattern.default" :password="newPassword.password" :label="$t('newPassword.repeatPassword')" type="password" :required=true>
                <i class="fas fa-lock sis-font-size-25"></i>
              </Inputfield>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" class="sis-login-button" :disabled="!valid" @click="sendNewPassword()">
            {{ $t('newPassword.button') }}
          </v-btn>
        </v-card-actions>
      </Card>
    </v-form>
  </div>
</template>

<script>
import Inputfield from '@/components/Inputfield.vue'
import Card from '@/components/Card.vue'

import rest from '../mixins/rest.js'

export default {
  name: 'nsis-NewPassword',
  components: {
    Inputfield,
    Card
  },
  mixins: [rest],
  data: function() {
    return {
      valid: false,
      error: false,
      token: undefined,
      pattern: {
        default: "^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[\\W_]).{12,}$"
      },
      newPassword: {
        password: "",
        repeatPassword: ""
      }
    }
  },
  created: function() {
    this.token = this.$route.query.token;
    if (!this.token) {
      console.log("Token not found!");
      this.error = true;
    }
  },
  methods: {
    sendNewPassword: function(){
      if(this.$refs.form.validate()) {
        this.callApi(() => {
          let api = this.getLoginApi();
          let token = this.$route.query.token;
          let opts = {
            "newPassword": this.newPassword
          };
          api.newPassword(token, opts, (error) => {
            if (error) {
              console.error(error);
              this.error = true;
            } else {
              console.log('Password successfully set.');
              this.$router.push({ name: "Login" });
            }
          });
        });
      }
    }
  }
}
</script>

<style scoped>
  .sis-push-center {
    position: absolute;
    top: 30%;
  }

  .sis-login-header {
    color: var(--white-text-color);
    background-color: var(--primary-color);
    text-align: center;
    padding: 20px !important;
  }

  .sis-login-button {
    width: 100%;
  }
</style>
