<template>
  <v-row class="sis-row">
    <v-col class="sis-push-center" cols="12" :xs="xs" :md="md">
      <v-card>
        <slot></slot>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'nsis-Card',
  props: {
    xs: String,
    md: String,
  }
}
</script>

<style>
  .v-list-item__title {
    padding: 20px 20px 0 20px !important;
  }

  .v-card__text {
    padding: 20px !important;
  }

  .v-card__actions {
    clear: both;
    padding: 0 20px 20px 20px !important;
  }
</style>